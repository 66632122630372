import { Component, OnInit, ViewChild } from "@angular/core";
import { NavController, LoadingController } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductsService } from "../services/products/products.service";
import { GlobalService } from "../services/global/global.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.page.html",
  styleUrls: ["./products.page.scss"],
})
export class ProductsPage implements OnInit {
  @ViewChild('focusInput', { static: true }) myInput;
  config: any = {};
  products: any = [];
  filter: any = [];
  _search: any = true;
  pesquisa: any = "";

  // params
  params: any = null;

  constructor(
    private productsService: ProductsService,
    public loadingController: LoadingController,
    private global: GlobalService,
    public navCtrl: NavController,
    private route: ActivatedRoute
  ) {
    this.config = JSON.parse(localStorage.getItem("config"));

    this.route.queryParams.subscribe((params) => {
      if (params && params.type && params.page) {
        this.params = {
          type: params.type,
          page: params.page,
        };
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.myInput.setFocus();
    }, 150);
  }

  goBack() {
    if (this.params) {
      this.navCtrl.navigateRoot(this.params.page, {
        queryParams: { type: this.params.type },
      });
    } else {
      this.navCtrl.navigateRoot("home");
    }
  }

  newProduct() {
    this.navCtrl.navigateRoot("productsform");
  }

  edit(item) {
    this.global.cadastro = item;
    this.navCtrl.navigateRoot("productsform");
  }

  select(item) {
    if (this.params) {
      this.global.state = {
        product: item,
      };

      this.navCtrl.navigateRoot(this.params.page, { queryParams: this.params });
    }
  }

  // search(evt) {
  //   const searchTerm = evt.srcElement.value;

  //   if (!searchTerm) {
  //     return;
  //   }

  //   if (this.config.searchType === "OFFLINE") {
  //     this.searchOffline(searchTerm);
  //   }

  //   if (this.config.searchType === "REAL_TIME") {
  //     this.searchOnline(searchTerm);
  //   }
  // }

  search() {
    if (!this.pesquisa) {
      return;
    }

    if (this.config.searchType === "OFFLINE") {
      this.searchOffline(this.pesquisa);
    }

    if (this.config.searchType === "REAL_TIME") {
      this.searchOnline(this.pesquisa);
    }
  }

  searchOffline(searchTerm) {
    let count = 0;
    var arr = this.global.products.filter(function (item) {
      if (item.medidas.indexOf(searchTerm.toUpperCase()) !== -1) {
        count++;
        if (count <= 10) return true;
        else return;
      }
    });
    this.filter = arr;
  }

  async searchOnline(searchTerm) {
    const loading = await this.loadingController.create({
      message: "Aguarde...",
    });
    loading.present().then(() => {
      this.productsService
        .getProductsByName(searchTerm)
        .then((res: any) => {
          console.log(res);

          const { data } = res;

          this.filter = data;
          loading.dismiss();
        })
        .catch((err) => {
          console.log(err);
          alert(JSON.stringify(err));
          loading.dismiss();
        });
    });
  }
}
