import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  config: any = {};
  options: any = {};

  constructor(public http: Http) { }

  configHeader() {
    this.config = JSON.parse(localStorage.getItem('config'));

    this.options = new Headers();
    this.options.append('X-database', this.config.database);
    this.options.append('X-database-host', this.config.db_host);
  }

  logIn(body) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.config.host}/api/login`, body, {
          headers: this.options
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          error => {
            reject(error.json());
          }
        );
    });
  }
}
