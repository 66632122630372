import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ClientsService {
  config: any = {};
  options: any = {};

  constructor(public http: Http) { }

  configHeader() {
    this.config = JSON.parse(localStorage.getItem("config"));

    this.options = new Headers();
    this.options.append("X-database", this.config.database);
    this.options.append("X-database-host", this.config.db_host);
  }

  getClients(page = 1) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.config.host}/api/clients?page=${page}`, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }

  getClientsByName(query) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.config.host}/api/clients?query=${query}`, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }

  create(body) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.config.host}/api/clients`, body, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }

  update(body, id) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .put(`${this.config.host}/api/clients/${id}`, body, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }
}
