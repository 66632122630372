import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NavController,
  LoadingController,
  ActionSheetController,
} from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";

import { ClientsService } from "../services/clients/clients.service";
import { GlobalService } from "../services/global/global.service";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.page.html",
  styleUrls: ["./clients.page.scss"],
})
export class ClientsPage implements OnInit {
  @ViewChild('focusInput', { static: true }) myInput;
  config: any = {};
  clients: any = [];
  filter: any = [];
  _search: any = true;
  pesquisa: any = "";

  // params
  params: any = null;

  constructor(
    private clientsService: ClientsService,
    private global: GlobalService,
    public loadingController: LoadingController,
    private route: ActivatedRoute,
    private router: Router,
    public navCtrl: NavController,
    public actionSheetController: ActionSheetController
  ) {
    this.config = JSON.parse(localStorage.getItem("config"));

    this.route.queryParams.subscribe((params) => {
      if (params && params.type && params.page) {
        this.params = {
          type: params.type,
          page: params.page,
        };
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.myInput.setFocus();
    }, 150);
  }

  goBack() {
    if (this.params) {
      this.navCtrl.navigateRoot(this.params.page, {
        queryParams: { type: this.params.type },
      });
    } else {
      this.navCtrl.navigateRoot("home");
    }
  }

  newClient() {
    this.navCtrl.navigateRoot("clientsform");
  }

  edit(item) {
    this.global.cadastro = item;
    this.navCtrl.navigateRoot("clientsform");
  }

  async select(item) {
    if (this.params) {
      this.global.state = {
        client: item,
      };

      this.navCtrl.navigateRoot(this.params.page, { queryParams: this.params });
    } else {
      const actionSheet = await this.actionSheetController.create({
        header: "Opções",
        buttons: [
          {
            text: "Definir Como Cliente Padrão",
            icon: "person-outline",
            handler: () => {
              let config = JSON.parse(localStorage.getItem("config"));
              config.defaultClient = item;
              localStorage.setItem("config", JSON.stringify(config));
            },
          },

          {
            text: "Remover Cliente Padrão",
            role: "destructive",
            icon: "trash",
            handler: async () => {
              console.log(item);
              let config = JSON.parse(localStorage.getItem("config"));
              delete config.defaultClient;
              localStorage.setItem("config", JSON.stringify(config));
            },
          },
          {
            text: "Cancelar",
            icon: "close",
            role: "cancel",
            handler: () => { },
          },
        ],
      });
      await actionSheet.present();
    }
  }

  search() {
    if (!this.pesquisa) {
      return;
    }

    if (this.config.searchType === "OFFLINE") {
      this.searchOffline(this.pesquisa);
    }

    if (this.config.searchType === "REAL_TIME") {
      this.searchOnline(this.pesquisa);
    }
  }

  // search(evt) {
  //   const searchTerm = evt.srcElement.value;

  //   if (!searchTerm) {
  //     return;
  //   }

  //   if (this.config.searchType === "OFFLINE") {
  //     this.searchOffline(searchTerm);
  //   }

  //   if (this.config.searchType === "REAL_TIME") {
  //     this.searchOnline(searchTerm);
  //   }
  // }

  searchOffline(searchTerm) {
    let count = 0;
    var arr = this.global.clients.filter(function (item) {
      if (item.Nome.indexOf(searchTerm.toUpperCase()) !== -1) {
        count++;
        if (count <= 10) return true;
        else return;
      }
    });
    this.filter = arr;
  }

  async searchOnline(searchTerm) {
    const loading = await this.loadingController.create({
      message: "Aguarde...",
    });
    loading.present().then(() => {
      this.clientsService
        .getClientsByName(searchTerm)
        .then(async (res: any) => {
          console.log(res);

          const { data } = res;

          this.filter = data;

          loading.dismiss();
        })
        .catch(async (err) => {
          console.log(err);
          alert(JSON.stringify(err));
          loading.dismiss();
        });
    });
  }
}
