import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { element } from "protractor";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  config: any = {};
  options: any = {};

  constructor(public http: Http) { }

  configHeader(arr = []) {
    this.config = JSON.parse(localStorage.getItem("config"));

    this.options = new Headers();
    this.options.append("X-database", this.config.database);
    this.options.append("X-database-host", this.config.db_host);

    arr.forEach((element) => {
      this.options.append(element.name, element.value);
    });
  }

  list(
    loja,
    vendedor,
    tipo,
    dtInit = "",
    dtFinish = "",
    campo = "",
    pesquisa = "",
    ordem = ""
  ) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.config.host}/api/sales?loja=${loja}&vendedor=${vendedor}&tipo=${tipo}&dtInit=${dtInit}&dtFinish=${dtFinish}&campo=${campo}&pesquisa=${pesquisa}&ordem=${ordem}`,
          {
            headers: this.options,
          }
        )
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }

  update(sale) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .put(`${this.config.host}/api/sales/${sale.id}`, sale, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }

  store(sale) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.config.host}/api/sales`, sale, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }

  print(id) {
    this.configHeader();
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.config.host}/api/sales/print/${id}`, {}, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }

  destroy(loja, vendedor, tipo, id) {
    this.configHeader([
      { name: "X-loja", value: loja },
      { name: "X-vendedor", value: vendedor },
      { name: "X-tipo", value: tipo },
    ]);
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${this.config.host}/api/sales/${id}`, {
          headers: this.options,
        })
        .subscribe(
          (result: any) => {
            resolve(result.json());
          },
          (error) => {
            reject(error.json());
          }
        );
    });
  }
}
