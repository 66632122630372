import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ActionSheetController,
  ToastController,
} from "@ionic/angular";
import { ActivatedRoute } from "@angular/router";

import { GlobalService } from "../services/global/global.service";
import { SalesService } from "../services/sales/sales.service";
import { LojasService } from "../services/lojas/lojas.service";
@Component({
  selector: "app-sales",
  templateUrl: "./sales.page.html",
  styleUrls: ["./sales.page.scss"],
})
export class SalesPage implements OnInit {
  type: any = null;
  sales: any = null;
  config: any = {};
  search: any = false;
  form: any = {
    dtInit: new Date().toISOString(),
    dtFinish: new Date().toISOString(),
  };
  lojas: any = [];
  user: any = {};

  constructor(
    public navCtrl: NavController,
    private route: ActivatedRoute,
    public actionSheetController: ActionSheetController,
    public toastController: ToastController,
    public global: GlobalService,
    private lojasService: LojasService,
    public salesService: SalesService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params.type) {
        this.type = params.type;
      }
    });

    /**
     * TODO:
     *
     * Se configrado em tempo real, realizar operações em tempo real:
     *    - Buscar (Busca com base no range de datas)
     *    - Listar (Listar as vendas do dia, se nao tiver, listar as ultimas 10 vendas realizadas)
     *    - Cadastrar
     *    - Editar
     *    - Excluir
     *
     * Se configurado Offline, realizar operações localmente:
     *    - Buscar (Busca com base no range de datas)
     *    - Listar (Listar as vendas cadastradas localmente)
     *    - Cadastrar
     *    - Editar
     *    - Excluir
     *    - Possibilidade de sincronizar
     *
     */
  }

  async ngOnInit() {
    this.config = JSON.parse(localStorage.getItem("config"));
    this.user = JSON.parse(localStorage.getItem("user"));
    this.form.loja = this.config.lojapadrao;

    this.lojas = await this.lojasService.list();

    if (this.global.salesFilter) {
      console.log(this.global.salesFilter);
      this.form = this.global.salesFilter;
      this.global.salesFilter = null;
      this.searchSales();
    } else {
      this.getSales();
    }
  }

  getLojas() { }

  getSales() {
    // obter do login do usuario
    // loja: 'ARACAJU',
    // caixa: 'MESTRE',
    // vendedor: 'MESTRE',

    if (this.config.searchType === "REAL_TIME") {
      this.salesService
        .list(this.form.loja, this.user.NOME, this.type)
        .then((res: any) => {
          console.log(res);
          this.sales = res;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.sales = localStorage.getItem(this.type.toLowerCase())
        ? JSON.parse(localStorage.getItem(this.type.toLowerCase()))
        : [];
    }
  }

  formatDateQuery(value) {
    let date = new Date(value);

    let day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
    let month =
      date.getMonth() <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let year = date.getFullYear();

    console.log(`${year}-${month}-${day}`);

    return `${year}-${month}-${day}`;
  }

  searchSales() {
    if (this.config.searchType === "REAL_TIME") {
      this.global.salesFilter = this.form;
      this.salesService
        .list(
          this.form.loja,
          this.user.NOME,
          this.type,
          this.formatDateQuery(this.form.dtInit),
          this.formatDateQuery(this.form.dtFinish),
          this.form.campo,
          this.form.pesquisa,
          this.form.ordem
        )
        .then((res: any) => {
          console.log(res);
          this.sales = res;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // busca local
    }
  }

  // changeDate() {
  //   let dtInit = new Date(this.dtInit);
  //   console.log(dtInit.getDate(), this.dtFinish);
  // }

  searchEnable() {
    this.search = !this.search;
  }

  newSale() {
    this.global.saleform = null;
    this.navCtrl.navigateRoot("salesform", {
      queryParams: { type: this.type },
    });
  }

  async print(index) {
    try {
      let sale = this.sales[index];

      const response = await this.salesService.print(sale.id);

      const toast = await this.toastController.create({
        message: 'Impressão realizada com sucesso.',
        duration: 2000,
      });

      toast.present();
    } catch (err) {
      console.log(err);
    }
  }

  edit(index) {
    let form = this.sales[index];
    form.index = index;
    this.global.saleform = { form, tab: 0 };
    this.navCtrl.navigateRoot("salesform", {
      queryParams: { type: this.type },
    });
  }

  async remove(index) {
    let message = "Venda foi removida.";

    if (this.type === "BUDGETS") message = "Orçamento foi removido.";
    if (this.type === "INPUTS") message = "Entrada foi removida.";

    const toast = await this.toastController.create({
      message,
      duration: 2000,
    });

    if (this.config.searchType === "REAL_TIME") {
      this.salesService
        .destroy(
          this.form.loja,
          this.user.NOME,
          this.type,
          this.sales[index].id
        )
        .then((res: any) => {
          this.sales.splice(index, 1);
          toast.present();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.sales.splice(index, 1);
      localStorage.setItem(this.type.toLowerCase(), JSON.stringify(this.sales));
      toast.present();
    }
  }

  async options(index) {
    const actionSheet = await this.actionSheetController.create({
      header: "Opções",
      buttons: [
        {
          text: "Editar",
          icon: "create-outline",
          handler: () => {
            let form = this.sales[index];
            form.index = index;
            this.global.saleform = { form, tab: 0 };
            this.navCtrl.navigateRoot("salesform", {
              queryParams: { type: this.type },
            });
          },
        },

        {
          text: "Excluir",
          role: "destructive",
          icon: "trash",
          handler: async () => {
            let message = "Venda foi removida.";

            if (this.type === "BUDGETS") message = "Orçamento foi removido.";
            if (this.type === "INPUTS") message = "Entrada foi removida.";

            const toast = await this.toastController.create({
              message,
              duration: 2000,
            });

            if (this.config.searchType === "REAL_TIME") {
              this.salesService
                .destroy(
                  this.form.loja,
                  this.user.NOME,
                  this.type,
                  this.sales[index].id
                )
                .then((res: any) => {
                  this.sales.splice(index, 1);
                  toast.present();
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              this.sales.splice(index, 1);
              localStorage.setItem(
                this.type.toLowerCase(),
                JSON.stringify(this.sales)
              );
              toast.present();
            }
          },
        },
        {
          text: "Cancelar",
          icon: "close",
          role: "cancel",
          handler: () => { },
        },
      ],
    });
    await actionSheet.present();
  }

  formatDate(value) {
    var [year, month, day] = value.split("-");
    return `${day}/${month}/${year}`;
  }

  fmt(value) {
    return parseFloat(value).toFixed(2);
  }
}
