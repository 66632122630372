import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { LoadingController, ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  public clients: any = [];
  public products: any = [];
  pageClients: any = 1;
  pageProducts: any = 1;
  configClients: any = {};
  configProducts: any = {};
  public loading: any;
  public cadastro: any = null;

  // estado global
  public state: any = null;
  public saleform: any = null;
  public salesFilter: any = null;

  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
    private storage: Storage
  ) {
    this.configClients = JSON.parse(localStorage.getItem("configClients"));
    this.configProducts = JSON.parse(localStorage.getItem("configProducts"));
  }

  async loadOffline() {
    if (this.clients.length == 0 || this.products.length == 0) {
      this.pageClients = 1;
      this.pageProducts = 1;
      this.loading = await this.loadingController.create({
        message: "Aguarde...",
      });
      this.loading.present();
      this.loadClientsOffline(this.pageClients);
    }
  }

  loadClientsOffline(page) {
    this.storage.get(`clients-${page}`).then(async (val) => {
      let arr = JSON.parse(val);

      if (arr) {
        arr.forEach((item) => {
          this.clients.push(item);
        });
        this.pageClients += 1;
        if (this.pageClients <= this.configClients.last_page)
          this.loadClientsOffline(this.pageClients);
        if (this.pageClients > this.configClients.last_page) {
          this.loading.dismiss();
          this.loadProductsOffline(this.pageProducts);
        }
      } else {
        this.loading.dismiss();
        const toast = await this.toastController.create({
          message: "É necessário atualizar as tabelas do seu dispositivo.",
          duration: 3000,
        });
        toast.present();
      }
    });
  }

  loadProductsOffline(page) {
    this.storage.get(`products-${page}`).then(async (val) => {
      let arr = JSON.parse(val);

      if (arr) {
        arr.forEach((item) => {
          this.products.push(item);
        });
        this.pageProducts += 1;
        if (this.pageProducts <= this.configProducts.last_page)
          this.loadProductsOffline(this.pageProducts);
        if (this.pageProducts > this.configProducts.last_page)
          this.loading.dismiss();
      } else {
        this.loading.dismiss();
        const toast = await this.toastController.create({
          message: "É necessário atualizar as tabelas do seu dispositivo.",
          duration: 3000,
        });
        toast.present();
      }
    });
  }

  formatDateQuery(value) {
    let date = new Date(value);

    let day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
    let month =
      date.getMonth() <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let year = date.getFullYear();

    // console.log(`${year}-${month}-${day}`);

    return `${year}-${day}-${month}`;
  }

  formatDate(value) {
    if (value) {
      const [year, month, day] = value.split("-");
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  }

  formatDateTime(value) {
    if (value) {
      const [date, time] = value.split(" ");
      const [year, month, day] = date.split("-");
      const [hours, minutes] = time.split(":");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    } else {
      return "";
    }
  }
}
