import { Component, OnInit } from "@angular/core";
import {
  Platform,
  LoadingController,
  ToastController,
  NavController,
} from "@ionic/angular";

import { LoginService } from "../services/login/login.service";

declare var window: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  height: any = 0;
  form: any = {};
  erro: any = null;

  constructor(
    platform: Platform,
    private loginService: LoginService,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public navCtrl: NavController
  ) {
    this.height = platform.height();
  }

  ngOnInit() {
    // this.init();

    let date = new Date();
    let config = JSON.parse(localStorage.getItem("config"));
    let month =
      date.getMonth() <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

    let nomecurto = config && config.nomecurto ? config.nomecurto : "";

    var qrcode = new window.QRCode(document.getElementById("qrcode"), {
      text: "http://www.mazzaway.com.br/" + nomecurto + month + ".php",
      width: 70,
      height: 70,
      colorDark: "#000000",
      colorLight: "#ffffff",
    });
  }

  async goToConfig() {
    let dataHora = new Date();
    let hora =
      dataHora.getHours() < 10
        ? "0" + dataHora.getHours()
        : dataHora.getHours();
    let minuto =
      dataHora.getMinutes() < 10
        ? "0" + dataHora.getMinutes()
        : dataHora.getMinutes();

    if (this.form.senha === `${hora}${minuto}`) {
      this.navCtrl.navigateRoot("config");
    } else {
      const toast = await this.toastController.create({
        message: "Senha Administrativa está incorreta!",
        duration: 2000,
      });
      toast.present();
    }
  }

  // init() {
  //   let user = JSON.parse(localStorage.getItem('user'));
  //   if (user != null) {
  //     this.navCtrl.navigateRoot('home');
  //   }
  // }

  async entrar() {
    const loading = await this.loadingController.create({
      message: "Aguarde...",
    });

    loading.present().then(() => {
      this.loginService
        .logIn(this.form)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res));
          loading.dismiss();

          this.navCtrl.navigateRoot("home");
        })
        .catch(async (err) => {
          loading.dismiss();

          const toast = await this.toastController.create({
            message: JSON.stringify(err),
            duration: 2000,
          });
          toast.present();
        });
    });
  }
}
