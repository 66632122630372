import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'config',
    loadChildren: () =>
      import('./config/config.module').then(m => m.ConfigPageModule)
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./clients/clients.module').then(m => m.ClientsPageModule)
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/products.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'sales',
    loadChildren: () =>
      import('./sales/sales.module').then(m => m.SalesPageModule)
  },
  {
    path: 'salesform',
    loadChildren: () =>
      import('./salesform/salesform.module').then(m => m.SalesformPageModule)
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'box',
    loadChildren: () => import('./box/box.module').then( m => m.BoxPageModule)
  },
  {
    path: 'estoque',
    loadChildren: () => import('./estoque/estoque.module').then( m => m.EstoquePageModule)
  },
  {
    path: 'fluxocaixa',
    loadChildren: () => import('./fluxocaixa/fluxocaixa.module').then( m => m.FluxocaixaPageModule)
  },
  {
    path: 'clientsform',
    loadChildren: () => import('./clientsform/clientsform.module').then( m => m.ClientsformPageModule)
  },
  {
    path: 'productsform',
    loadChildren: () => import('./productsform/productsform.module').then( m => m.ProductsformPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
